import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

//Styles
import * as header from '../../styles/Header.module.scss'
import * as integration from '../../styles/Integration.module.scss'
import logo from '../../images/flow_logo.png'

const Hubspot = ({ pageContext: { locale } }) => {
  const homeLink = `/${locale}/`
  return (
    <div className="wrapper">
      <div className={header.navigation}>
        <Link className={header.logo} to={homeLink}>
          <img alt="Flow Logo" src={logo} />
        </Link>
      </div>

      <section className={integration.section}>
        <div className={integration.container}>
          <h2>Overview</h2>
          <div>
            <p>
              With Flow for HubSpot you can improve your employees efficiency by
              integrating Flow services directly into HubSpot. Allow your agents
              to focus on the conversation instead of switching back-and-forth
              between tools. Get the information and functionalities you need
              inside HubSpot, while saving valuable time and minimizing
              distractions.
            </p>

            <p>
              <i>
                Disclaimer: The HubSpot integration is currently not fit for
                handling inbound calls.
              </i>
            </p>
          </div>
        </div>
      </section>

      <section className={integration.section}>
        <div className={integration.container}>
          <h2>Key benefits</h2>

          <div className={integration.featuresWrapper}>
            <div className={integration.featureItem}>
              <div className={integration.content}>
                <h4>Delight your customers</h4>
                <span>
                  Flow for HubSpot displays essential customer information
                  during the call, enabling a personalised and well-informed
                  customer interaction.
                </span>
              </div>
            </div>

            <div className={integration.featureItem}>
              <div className={integration.content}>
                <h4>Streamline your work</h4>
                <span>
                  With Flow for HubSpot, agents no longer need to switch between
                  applications during an interaction, saving time but also
                  maintaining their focus on what matters the most: your
                  customer.
                </span>
              </div>
            </div>
            <div className={integration.featureItem}>
              <div className={integration.content}>
                <h4>Empower your agents</h4>
                <span>
                  Flow for HubSpot removes most of the mundane and error prone
                  manual data entry required to keep data consistent offering a
                  more efficient work day with less frustration.
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={integration.section}>
        <div className={integration.container}>
          <h2>Key features</h2>

          <div className={integration.featuresWrapper}>
            <div className={integration.featureItem}>
              <div className={integration.content}>
                <h4>Embedded softphone</h4>
                <span>
                  Get the whole Flow web app embedded in your CRM with complete
                  softphone capabilities such as receiving, placing and
                  transferring calls (incl. click-to-dial), muting and putting
                  the caller on hold.
                </span>
              </div>
            </div>

            <div className={integration.featureItem}>
              <div className={integration.content}>
                <h4>Contact lookup & history</h4>
                <span>
                  For both incoming and outgoing calls, Flow looks up the number
                  and fetches all relevant contact information from your CRM in
                  a pop-up. View the contact’s call and case history, listen to
                  recorded calls and voicemails, as well as navigate to the
                  contact card with a single click. You can also place a call
                  directly from the contact card.
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={integration.section}>
        <div className={integration.container}>
          <h2>How to setup the integration</h2>
          <h4>Requirements</h4>
          <div className="setup-list">
            <ul className={integration.ulList}>
              <li className={integration.ulListItem}>
                "CRM Integration" user addon for Flow Fixed or Mobile user
                licence.
              </li>
              <li className={integration.ulListItem}>
                Paid Hubspot account, either Hubspot Sales or Service Hub in
                order to use their call-out feature
              </li>
            </ul>
          </div>
          <h4>Installation instructions</h4>
          <div className="setup-list">
            <ul className={integration.ulList}>
              <li className={integration.ulListItem}>
                Make sure you have a phone number from Flow and admin access in
                HubSpot
              </li>
              <li className={integration.ulListItem}>
                Click on the following installation{' '}
                <a href="https://app.hubspot.com/oauth/authorize?client_id=ddfafdf2-934a-4d42-a0ca-7d8fd217a963&redirect_uri=https://admin.joinflow.com/signin.jsp?state=hubspot_flow&scope=contacts%20content%20tickets">link</a> while logged in as
                an administrator, and authorise the integration
              </li>
              <li className={integration.ulListItem}>
                Install the app and authorise your team.
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section className={`${integration.section} ${integration.contactUs}`}>
        <div className={`${integration.container} ${integration.morePadding}`}>
          <h2>Questions about integrations?</h2>
          <span>
            Contact us at <a href="tel:+46980330859">+46980330859</a> or{' '}
            <a href="mailto:support@joinflow.com">support@joinflow.com</a> to
            learn more.
          </span>
        </div>
      </section>
    </div>
  )
}

Hubspot.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
}

export default Hubspot
