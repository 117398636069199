// extracted by mini-css-extract-plugin
export var contactUs = "Integration-module--contactUs--g7HfL";
export var container = "Integration-module--container--OY9TY";
export var content = "Integration-module--content--J8U9a";
export var featureItem = "Integration-module--featureItem--wFS5f";
export var featuresWrapper = "Integration-module--featuresWrapper--1Sh51";
export var morePadding = "Integration-module--morePadding--j0iWM";
export var olList = "Integration-module--olList--+JHFn";
export var olListItem = "Integration-module--olListItem--vLZgf";
export var section = "Integration-module--section--Dbcky";
export var setupListHint = "Integration-module--setupListHint--qep7d";
export var ulList = "Integration-module--ulList--j2yBy";
export var ulListItem = "Integration-module--ulListItem--F4m99";